/*
 * Copyright by Intland Software
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Intland Software. ("Confidential Information"). You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Intland.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { defaultConfig } from "./iframe-widget-config";
var context = window['contextPath'];
var getToken = function () {
    return fetch("".concat(context, "/jwt/authenticate.spr"))
        .then(function (response) { return response.json(); })
        .then(function (json) { return json.token; });
};
var IFrameWidgetHandlers = /** @class */ (function () {
    function IFrameWidgetHandlers(config, iFrame, selectedItem) {
        this.iFrame = iFrame;
        this.selectedItem = selectedItem;
        this.config = __assign(__assign({}, defaultConfig), (config || {}));
    }
    IFrameWidgetHandlers.prototype.authenticate = function () {
        return getToken().then(function (token) { return ({ token: token }); });
    };
    IFrameWidgetHandlers.prototype.resize = function (request) {
        this.iFrame.height = request.payload.height.toString();
        return Promise.resolve();
    };
    IFrameWidgetHandlers.prototype.getSelectedItem = function () {
        return Promise.resolve({ itemId: this.selectedItem });
    };
    IFrameWidgetHandlers.prototype.reloadItem = function (request) {
        return this.config.reloadItemCallback(request.widgetId, request.payload.itemId) || Promise.resolve();
    };
    IFrameWidgetHandlers.prototype.getConfig = function () {
        return Promise.resolve({ config: this.config.remoteConfig });
    };
    IFrameWidgetHandlers.prototype.getBaseURL = function () {
        var baseUrl = new URL(context + '/api', window.location.origin);
        return Promise.resolve({ baseURL: baseUrl.toString() });
    };
    return IFrameWidgetHandlers;
}());
export { IFrameWidgetHandlers };
